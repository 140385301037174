<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>접수 시작일<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <DatePicker
              maxWidth="388px"
              :value.sync="viewModel.model.receiptStartAt"/>
          </td>
        </tr>
        <tr>
          <th>리워드 이미지<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <FileUpload
              :dataList.sync="viewModel.model.rewardImgUrlList"
              :canMultiple="false"
              :acceptList="['png', 'jpg', 'jpeg']"
              :size="[864, 357]"
              sizeType="FIT"
              :saveTrigger="saveTrigger"
              :isSaved.sync="isSaved"
            />
          </td>
        </tr>
        <tr>
          <th>리워드<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="리워드를 입력해주세요"
              :value.sync="viewModel.model.reward"/>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="STOCK_GAME_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          @onClickBtn="onClickPopupComplete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

import { checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin';

// viewModel
import StockGameDetailViewModel from '@/views/stock/game/viewModel/StockGameDetailViewModel'

export default {
  name: 'StockGameRegister',
  mixins:[FileSaveMixin],
  components: {
    PageWithLayout,
    TableView,
    IconSvg,
    Input,
    DatePicker,
    FileUpload,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new StockGameDetailViewModel(),
    }
  },
  methods:{
    checkPastDate(date, returnText){
      return checkPastDate(date, returnText);
    },
    onClickPopupComplete(){
      if(this.viewModel.model.rewardImgUrlList.length > 0 && this.viewModel.model.rewardImgUrlList[0].file){
        this.onSave();
      }else{
        this.viewModel.onCompleteFileSave();
      }
    },
  }
}
</script>

<style scoped>
</style>
